<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <log-menu></log-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="content-title auto">
          <div class="coustom-title fl">人脸识别</div>
        </div>
        <div id="logaudit-search-bar1" class="m-t-5">
          <a-form-model
              id="no-margin-form"
              layout="inline"
              ref="logForm"
              :model="filter"
              class="ant-advanced-search-form"
            >
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="学工号" prop="schoolid">
                  <a-input v-model="filter.schoolid" placeholder="请输入学工号" size="small" allowClear style="width:160px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="时间" prop="time">
                  <a-range-picker v-model="filter.time" :show-time="{ format: 'HH:mm:ss' }" valueFormat="YYYY-MM-DD HH:mm:ss" size="small" :allowClear="false" style="width:324px;">
                    <a-icon slot="calendar" type="smile" />
                    <template slot="renderExtraFooter">
                      查询区间最长半年
                    </template>
                  </a-range-picker>
                </a-form-model-item>
              </a-col>
              <!-- <a-col :span="8" v-if="logBarsStatus">
                <a-form-model-item label="登录IP" prop="ip">
                  <a-input v-model="filter.ip" placeholder="请输入登录IP" size="small" allowClear style="width:224px;"/>
                </a-form-model-item>
              </a-col> -->
              <a-col :span="3">
                <div class="fr log-btns" style="margin-top: 2px;margin-right:8px;">
                  <a-button type="link" size="small" @click="logReset">重置</a-button>
                  <a-button type="primary" size="small" @click="searchHandle">查询</a-button>
                </div>
              </a-col>
              <!-- <a-col :span="8" v-if="!logBarsStatus">
                <a-form-model-item label=" ">
                  <div class="logbar-btn-box">
                    <div class="fl log-btns">
                      <a-button type="link" size="small" @click="logReset">重置</a-button>
                      <a-button type="primary" size="small" @click="searchHandle">查询</a-button>
                    </div>
                    <div class="fr log-btns">
                      <a-button type="link" size="small" @click="logBarsStatus = true">展开 <a-icon type="down" /></a-button>
                    </div>
                  </div>
                </a-form-model-item>
              </a-col> -->
            </a-row>
            <a-row v-if="logBarsStatus">
              <a-col :span="8">
                <a-form-model-item label="日志类型" prop="target">
                  <a-select v-model="filter.target" placeholder="日志类型" size="small" allowClear style="width:112px;">
                    <a-select-option :value="item.id" v-for="(item, index) in filters['typeOptions']" :key="index">{{item.name}}</a-select-option>
                  </a-select>
                  <a-select v-model="filter.result" placeholder="操作结果" size="small" allowClear style="width:102px;margin-left: 10px;">
                    <a-select-option :value="item.id" v-for="(item, index) in filters['resultOptions']" :key="index">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <!-- <a-form-model-item label="详情" prop="comments">
                  <a-input v-model="filter.comments" placeholder="请输入关键词" size="small" allowClear style="width:224px;"/>
                </a-form-model-item> -->
              </a-col>
              <a-col :span="8">
                <a-form-model-item label=" ">
                  <div class="logbar-btn-box">
                    <div class="fl log-btns">
                      <a-button type="link" size="small" @click="logReset">重置</a-button>
                      <a-button type="primary" size="small" @click="searchHandle">查询</a-button>
                    </div>
                    <div class="fr log-btns">
                      <a-button type="link" size="small" @click="logBarsStatus = false">收起 <a-icon type="up" /></a-button>
                    </div>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <div slot="tableindex" slot-scope="text,record,index">
              {{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}
            </div>
            <div slot="target" slot-scope="text">
              <span>{{filters['typeOptions'].find(item => {return item.id === text}) ? filters['typeOptions'].find(item => {return item.id === text})['name'] : ''}}</span>
            </div>
            <div slot="result" slot-scope="text">
              <span v-if="text === 'Y'">
                <a-badge status="success" :text="filters['resultOptions'].find(item => {return item.id === text}) ? filters['resultOptions'].find(item => {return item.id === text})['name'] : ''" />
              </span>
              <span v-else>
                <a-badge status="error" :text="filters['resultOptions'].find(item => {return item.id === text}) ? filters['resultOptions'].find(item => {return item.id === text})['name'] : ''" />
              </span>
              <!-- <span>{{filters['resultOptions'].find(item => {return item.id === text}) ? filters['resultOptions'].find(item => {return item.id === text})['name'] : ''}}</span> -->
            </div>
            <!-- <div slot="comments" slot-scope="text">
              <div :title="text" style="width: 100%;overflow:hidden;white-space:normal; word-break:break-all;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;text-overflow:ellipsis;overflow:hidden">
                {{text}}
              </div>
            </div> -->
            <!-- <div slot="operation_before" slot-scope="text, record">
              <a href="javascript:;" @click="showDetial(record)" class="operationlink ellipsis">
                操作前：
                <span v-if="record.operation_before">
                  <span v-for="(item, index) in record.operation_before" :key="index">
                    {{record.table_columns[index]}} > {{item}}，
                  </span>
                </span>
                操作后：
                <span v-if="record.operation_after">
                  <span v-for="(item, index) in record.operation_after" :key="index">
                    {{record.table_columns[index]}} > {{item}}，
                  </span>
                </span>
              </a>
            </div>
            <div slot="operated_schoolid" slot-scope="text">
              <span v-if="text">学工号：{{text}}</span>
            </div> -->
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { Log } from '@/model'
export default {
  data () {
    return {
      loading: true,
      dialogTableLoading: false,
      logBarsStatus: false,
      filters: {},
      filter: {
        schoolid: '',
        target: undefined,
        result: undefined,
        time: [
          this.util.getDateString(new Date()) + ' 00:00:00',
          this.util.getDateString(new Date()) + ' 23:59:59'
        ],
        current_page: 1,
        page_size: 10
      },
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'tableindex',
          scopedSlots: { customRender: 'tableindex' },
          width: 70
        },
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '日志类型',
          dataIndex: 'target',
          scopedSlots: { customRender: 'target' }
        },
        {
          title: '调用结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' }
        },
        {
          title: '时间',
          dataIndex: 'createdDate'
        }
        // {
        //   title: '操作',
        //   fixed: 'right',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' },
        //   customCell: () => {
        //     return {
        //       style: {
        //         'min-width': '80px',
        //       }
        //     }
        //   }
        // }
      ],
      tabledata: [],
      count: 0,
      visible: false,
      count2: 0,
      logVisible: false,
      detailSearchFilter: {
        batch_operator_schoolid: '',
        batch_operation_time: '',
        page_size: 10,
        current_page: 1
      },
      detailTableHeader: [
        {
          title: '操作IP',
          dataIndex: 'operator_ip',
          width: 150
        },
        {
          title: '操作人',
          dataIndex: 'operator_schoolid'
        },
        {
          title: '操作类型',
          dataIndex: 'operation_type'
        },
        {
          title: '时间',
          dataIndex: 'operation_time',
          width: 170
        },
        {
          title: '操作',
          fixed: 'right',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'min-width': '80px',
              }
            }
          }
        }
      ],
      detailTableData: [],
      current_row: {},
      detailVisible: false
    }
  },
  // watch: {
  //   'filter': {
  //     handler: function (val) {
  //       this.filter.current_page = 1
  //       this.getList()
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    showDetial (row) {
      this.current_row = row
      this.detailVisible = true
    },
    detailClose () {
      this.detailVisible = false
    },
    detailSearch () {
      this.detailSearchFilter.current_page = 1
      this.logDetailList()
    },
    getPageMes2 (obj) {
      this.detailSearchFilter.current_page = obj.pageNumber
      this.detailSearchFilter.page_size = obj.pageSize
      this.logDetailList()
    },
    logDetail (row) {
      this.logVisible = true
      this.detailSearchFilter.batch_operator_schoolid = row.operator_schoolid
      this.detailSearchFilter.batch_operation_time = row.operation_time
      this.logDetailList()
    },
    async logDetailList () {
      let detailSearchFilter = {
        batch_operator_schoolid: this.detailSearchFilter.batch_operator_schoolid,
        batch_operation_time: this.detailSearchFilter.batch_operation_time,
        page_size: this.detailSearchFilter.page_size,
        current_page: this.detailSearchFilter.current_page
      }
      this.dialogTableLoading = true
      let res = await Log.getList(detailSearchFilter)
      this.dialogTableLoading = false
      if (!res['code']) {
        this.detailTableData = res['data']
        this.count2 = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    logDetailClose () {
      this.logVisible = false
      this.detailSearchFilter = {
        batch_operator_schoolid: '',
        batch_operation_time: '',
        page_size: 10,
        current_page: 1
      }
    },
    logReset () {
      this.filter['schoolid'] = ''
      this.filter['target'] = undefined
      this.filter['result'] = undefined
      this.filter['time'] = undefined
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getList()
    },
    searchHandle () {
      this.filter.current_page = 1
      this.getList()
    },
    async getList () {
      this.loading = true
      let form = {
        schoolid: '%' + this.filter['schoolid'] + '%',
        target: this.filter['target'],
        result: this.filter['result'],
        query_time_from: this.filter['time'] && this.filter['time'][0] ? this.filter['time'][0] : '',
        query_time_to: this.filter['time'] && this.filter['time'][1] ? this.filter['time'][1] : '',
        current_page: this.filter['current_page'],
        page_size: this.filter['page_size']
      }
      let res = await Log.getFaceList(form)
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    onClose () {
      this.visible = false
    },
    async getCode () {
      let res = await Log.getCode('auditType')
      this.filters['auditType'] = res['data']
      this.filters['typeOptions'] = [{"id":"match","name":"人脸比对"},{"id":"verify","name":"身份证核验"}]
      this.filters['resultOptions'] = [{"id":"Y","name":"成功"},{"id":"N","name":"失败"}]
      this.getList()
    }
  },
  created () {
    this.getCode()
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.content {
  height: 100%;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}

.drawer-bar {
  padding: 5px 0 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.drawer-bar-bot {
  padding-top: 20px;
  border: none;
}

.drawer-item {
  padding: 15px 0 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.drawer-content {
  margin-top: 5px;
}
.drawer-content3 {
  margin-top: 15px;
}

.restfuls-box {
  width: 312px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 2px;
  height: 113px;
  padding: 5px;
  overflow-y: auto;
}
.restfuls-box ul li {
  line-height: 22px;
  width: 100%;
  overflow: hidden;
}
.restfuls-box ul li:hover {
  background: #E6F7FF;
  cursor: pointer;
}
.restfuls-box ul li span {
  float: left;
  margin-left: 10px;
}
.restfuls-box ul li .del-icon {
  float: right;
  margin-top: 4px;
  margin-right: 20px;
  color: #1890FF;
  display: none;
}
.restfuls-box ul li:hover .del-icon {
  display: block;
}

.logbar-btn-box {
  width: 300px;
  margin: 2px auto 0;
  margin-left: -75px;
}
.log-page {
  padding: 10px 0;
  height: auto;
  overflow: hidden;
}

.detailbox {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}
.detail-before {
  padding-bottom: 20px;
}

.operationlink {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.operationlink:hover {
  text-decoration: underline;
}

.log-table {
  width: 100%;
}
.tablehead {
  width: 100%;
  display: flex;
}
.tableheadcolumn {
  flex: 1;
  height: 30px;
  background: #fafafa;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  padding: 0 20px;
}
.tableheadcolumn1 {
  flex: 0 0 20%;
}
.tablebody {
  width: 100%;
}
.tablebodyh {
  display: flex;
  width: 100%;
}
.tablebodycolumn {
  float: left;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  flex: 1;
  padding: 0 20px;
}
.tablebodycolumn1 {
  flex: 0 0 20%;
}
</style>
